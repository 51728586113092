// @import "./variables";
// @import "./landing";
// @import "./reset-pwd.scss";
// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap&family=Roboto:wght@300;400;500;700;900&display=swap&family=Open+Sans:wght@500&display=swap&family=Inter:wght@200;500;600&display=swap");

// html {
//   scroll-behavior: smooth !important;
// }

// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//     sans-serif;
//   -moz-osx-font-smoothing: grayscale;
// }

// .main {
//   text-align: center;
//   background-color: $background-color;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .content {
//   width: 100%;
// }

@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

* {
  color: #ffffff;
  font-family: 'Sofia Sans', sans-serif;
}

h1 {
  color: #eebd42;
  font-weight: 900;
  font-size: 83px;
}

h2 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 24px;
}

h2 strong {
  font-weight: 700;
}

p {
  font-weight: 400;
  font-size: 22px;
}

a {
  text-decoration: underline;
  color: #ffffff !important;
}

body {
  background-color: #606060;
}

.main-section {
  height: 100vh;
  background-image: url(../assets/bg-contruction@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media all and (max-width: 991px) {
  .main-section {
    height: 100vh;
    background-image: none;
  }

  h1 {
    font-size: 58px;
  }
}
